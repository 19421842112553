import { authenticatedCall } from "./authenticated-call"

interface Props {
	referenceName: string
	managerUrl: string
	websiteName: string


}
export const getInlineCSSURL = async ({ managerUrl, websiteName, referenceName }: Props): Promise<string | null> => {

	//if we already have a full url... just return it
	if (referenceName && referenceName.startsWith("https://")) return referenceName
	if (referenceName && referenceName.startsWith("http://")) return referenceName.replace("http://", "https://")

	var url = "/json/Global/GetInlineCssUrl";

	return authenticatedCall({ managerUrl, websiteName, url, args: { referenceNames: referenceName } })


}