import { get } from "jquery"
import { getInlineTemplateURL } from "./data-access/getInlineTemplateURL"
import { getCSSDeps } from "./deps/getCSSDeps"
import { getScriptDeps } from "./deps/getScriptDeps"
import { sendMessage } from "./messages/send-message"
import { clearAppElement } from "./app-element/clear-out"
import { showErrorMessage } from "./app-element/show-error-message"

interface Props {
	websiteName: string
	fieldName: string
	fieldType: string
	managerUrl: string
	readonly: boolean
	contentItem: any
	field: any,
	attemptNum: number
}

export const renderField = async ({ contentItem, field, fieldName, fieldType, managerUrl, readonly, websiteName, attemptNum }: Props) => {

	try {


		//@ts-ignore
		const ko = window.ko

		const koContentItem = ko.mapping.fromJS(contentItem);


		const pluginOptions = {
			$elem: $("#app"),
			fieldSetting: field,
			// fieldBinding: fieldBinding,
			contentID: ko.observable(contentItem.ContentID || -1),
			contentItem: koContentItem,
			// viewModel: parentViewModel,
			readonly: !!readonly,

			fieldValue: ko.observable(contentItem.Values[fieldName]),

			fieldBinding: ko.pureComputed({
				read: function () {
					return koContentItem.Values[fieldName]();
				},

				write: function (value) {

					koContentItem.Values[fieldName](value)
					sendMessage({
						eventName: "value",
						websiteName,
						fieldName,
						fieldType,
						value
					});
				},
				owner: self
			})
		}

		//MEGA-HACK - make this a global variable because it's cold out and I'm lazy
		//@ts-ignore
		window.pluginOptions = pluginOptions

		//@ts-ignore
		const customFieldObj = ContentManager.Global.CustomInputFormFields.find((f) => `${f.ReferenceName}`.toLowerCase() === fieldType.toLowerCase());

		if (customFieldObj) {

			//load the dependencies
			try {
				await getCSSDeps({ managerUrl, websiteName, dependencies: customFieldObj.DependenciesCSS })
			} catch (error) {
				console.warn("Error loading css dependencies for custom field:", fieldName, "type:", fieldType, error)
			}


			try {
				await getScriptDeps({ managerUrl, websiteName, dependencies: customFieldObj.DependenciesJS })
			} catch (error) {
				console.warn("Error loading script dependencies for custom field:", fieldName, "type:", fieldType, error)
			}



			clearAppElement()

			//load the template
			if (customFieldObj.Template) {

				console.log("Rendering custom field:", fieldName, "type:", fieldType, "with template", customFieldObj.Template)

				const templateUrl = await getInlineTemplateURL({
					websiteName,
					managerUrl,
					referenceName: customFieldObj.Template
				})



				if (!templateUrl) {
					showErrorMessage({ msg: `Error loading custom field: ${fieldType}.` })
					console.error("Could not get template URL for custom field.", customFieldObj)
					return
				}




				const res = await fetch(templateUrl);
				const html = await res.text();
				var $tmpl = $(html);

				//we need to apply bindings to one parent node, so there should only be one dom element containing everything
				if ($tmpl.length > 1) {
					showErrorMessage({ msg: `Error loading custom field: ${fieldType}.` })
					console.error('The HTML template for the ' + fieldName + ' must have a single parent DOM element.')
					return
				}

				$("#app").empty()
				$("#app").append($tmpl);

				//3. apply the viewmodel
				var fieldVM = new customFieldObj.ViewModel(pluginOptions);

				//@ts-ignore
				window.ko.applyBindings(fieldVM, $tmpl.get(0));

			} else {

				$("#app").empty()

				console.log("Rendering custom field:", fieldName, "type:", fieldType, "with no template")

				//no template, just render the field
				customFieldObj.Render(pluginOptions);
			}

		} else {

			if (attemptNum < 3) {
				setTimeout(() => {
					console.error("Custom field type not found: ", fieldType, "retry attempt:", attemptNum);
					renderField({ contentItem, field, fieldName, fieldType, managerUrl, readonly, websiteName, attemptNum: attemptNum + 1 })
				}, 500)
			} else {

				console.error("Custom field type not found: ", fieldType);
			}
		}

		const mainElement = document.getElementById("main-parent") || document.body

		if (mainElement) {
			let currentHeight = 0
			function detectHeight() {

				const height = mainElement.scrollHeight

				if (height === currentHeight) return

				currentHeight = height

				sendMessage({
					eventName: "height",
					websiteName,
					fieldName,
					fieldType,
					height
				});
			}

			detectHeight()
			//new ResizeObserver(detectHeight).observe(mainElement)
			setInterval(detectHeight, 100)
		}

	} catch (error) {
		console.warn(`Error rendering custom field: ${fieldName} of type: ${fieldType}`);
		console.warn("Error", error)
	}


}