import { getInlineURL } from "../data-access/getInlineURL"



interface Dependancy {
	id: string
	src: string
}

interface Props {
	managerUrl: string
	websiteName: string
	dependencies: Dependancy[] | null | undefined

}

/**
 * Load the CSS dependencies.
 * @param param0 { managerUrl, websiteName, dependencies}
 */
export const getCSSDeps = async ({ managerUrl, websiteName, dependencies }: Props) => {
	if (!dependencies) return
	for (var x in dependencies) {
		let url = await getInlineURL({ managerUrl, websiteName, src: dependencies[x].src, type: 'css' })
		if (url) {
			if (url.startsWith("http")) url = url.replace("http://", "https://")
			$('head').append('<link id="' + dependencies[x].id + '" rel="stylesheet" href="' + url + '" type="text/css" />');
		}

	}
}