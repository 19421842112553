import { getInlineURL } from "../data-access/getInlineURL"



interface Dependancy {
	id: string
	src: string
}

interface Props {
	managerUrl: string
	websiteName: string
	dependencies: Dependancy[] | null | undefined

}

/**
 * Load the CSS dependencies.
 * @param param0 { managerUrl, websiteName, dependencies}
 */
export const getScriptDeps = async ({ managerUrl, websiteName, dependencies }: Props) => {
	if (!dependencies) return

	for (var x in dependencies) {
		let url = await getInlineURL({ managerUrl, websiteName, src: dependencies[x].src, type: 'js' })
		if (url) {
			if (url.startsWith("http")) url = url.replace("http://", "https://")
			console.log("Loading script: ", url)
			await $.getScript(url)
		}
	}
}