//@ts-nocheck

export const loadCustomKnockoutBindings = () => {

	window.ko.bindingHandlers.select2 = {
		init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
			var options = ko.unwrap(valueAccessor());

			if (ko.isWritableObservable(options.value)) {
				$(element).change(function () {
					if (options.value() != $(element).val()) {
						options.value($(element).val());
					}
				});
			}

			$(element).select2(options);

			if (options.change) {
				$(element).on("change", options.change);
			}

			if (ko.unwrap(options.enableDragAndDrop)) {
				//add drag and drop...
				$(element).select2("container").find("ul.select2-choices").sortable({
					containment: 'parent',
					start: function () { $(element).select2("onSortStart"); },
					stop: function () { $(element).select2("onSortEnd"); }
				});
			}

			//subscribe to the value parameter if it is sent in...
			if (ko.isSubscribable(options.value)) {
				//also set the
				options.value.subscribe(function () {
					var v = ko.unwrap(options.value);
					if ($(element).val() != v) {
						$(element).val(v).change();
					}
				});
			}


		},

		update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
			var options = ko.unwrap(valueAccessor());

			//set the value on update
			var value = ko.unwrap(options.value);
			if (value && $(element).val() != value) {
				$(element).val(value).change();
			}

		}
	}
}