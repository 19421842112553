import { getInlineCSSURL } from "./getInlineCSSURL"
import { getInlineScriptURL } from "./getInlineScriptURL"
import { getInlineTemplateURL } from "./getInlineTemplateURL"


interface Props {
	src: string
	managerUrl: string
	websiteName: string
	type: string

}

export const getInlineURL = async ({ managerUrl, src, type, websiteName }: Props) => {

	const referenceName = src
	//is this a url or an inline code reference file?
	if (src.toLowerCase().indexOf('http://') > -1 || src.toLowerCase().indexOf('https://') > -1) {
		//if it's a url, use it
		return src
	} else {
		//see if this string matches a reference name in inline code
		if (type === 'js') {
			return await getInlineScriptURL({ managerUrl, websiteName, referenceName })
		} else if (type === 'html') {
			return await getInlineTemplateURL({ managerUrl, websiteName, referenceName })
		} else if (type === 'css') {
			return await getInlineCSSURL({ managerUrl, websiteName, referenceName })
		} else {
			return src;
		}
	}
}