//@ts-nocheck
interface Props {
	managerUrl: string
	websiteName: string
}
export const setupShowMessage = ({ websiteName }: Props) => {

	const Message = function (message, title, messageType, actions, size, inputConfirmation) {
		var self = this;

		self.showing = ko.observable(true);

		if (!actions || actions.length < 0) {

			actions = [{
				name: "OK",
				defaultAction: true,
				cancelAction: true
			}];
		}


		if (messageType == null || $.trim(messageType) == "") {
			messageType = ContentManager.Global.MessageType.Info;
		}
		if ($.trim(title) == "") title = null;

		self.isHtml = ko.observable(true);
		self.messageType = ko.observable(messageType);
		self.message = ko.observable(message);
		self.bottomMessage = ko.observable(null);
		self.title = ko.observable(title);

		self.templateName = ko.observable(null);
		self.templateData = ko.observable(null);

		/// <summary>This needs to be a css percent string eg: 45%</summary>
		self.progressPercent = ko.observable(null);

		self.actionsStorage = ko.observableArray();

		self.hasCancelAction = false;

		self.actions = ko.pureComputed({
			write: function (values) {
				self.hasCancelAction = false;

				for (var i = 0; i < values.length; i++) {

					if (!values[i].defaultAction) {
						values[i].defaultAction = false;
					}

					if (!$.isFunction(values[i].disable)) {
						values[i].disable = ko.observable(false);
					}

					if (!values[i].stayVisible) {
						values[i].stayVisible = false;
					}

					if (values[i].cancelAction) {
						self.hasCancelAction = true;
					} else {
						values[i].cancelAction = null;
					}
				}

				self.actionsStorage(values);
			},
			read: function () {
				return self.actionsStorage();
			},
			owner: self
		});

		self.doCancelAction = function () {
			//this happens when the dialog is closed...

			var cancelActions = self.actions().filter(function (a) {

				return a.cancelAction == true;
			});

			if (cancelActions != null && cancelActions.length > 0) {
				action = cancelActions[0];
				//do the actions...
				if ($.isFunction(action.callback)) {
					action.callback();
				}
			}

			//remove this Message
			V.Navigation.messages().remove(self.message);
		}

		self.doingAction = ko.observable(false);

		self.inputConfirmation = ko.observable(false);
		self.valueToMatch = ko.observable();
		self.valueToType = ko.observable('');

		if (inputConfirmation) {
			self.inputConfirmation(true);
			self.valueToMatch(inputConfirmation.valueToMatch);
			self.inputConfirmationLabel = ko.observable(inputConfirmation.label);
		}

		self.disableActions = ko.pureComputed(function () {
			return ko.unwrap(self.doingAction) || (ko.unwrap(self.inputConfirmation) && ko.unwrap(self.valueToMatch) !== ko.unwrap(self.valueToType));
		});

		self.doAction = function (action) {
			if (!ko.unwrap(self.doingAction)) {
				self.doingAction(true);
				//destroy the dialog then do the action and remove
				if (!action.stayVisible) {
					self.modalOptions.destroy();
				}

				//do the actions...
				if ($.isFunction(action.callback)) {
					action.callback();
				}

				if (!action.stayVisible) {
					//remove this Message

					setTimeout(function () {
						self.doingAction(false);
					}, 10000);
				} else {
					self.doingAction(false);
				}
			}
		}


		self.hide = function () {

			//remove this Message
			//TODO: hide this...
			self.modalOptions.destroy();
		}

		//setup the actions
		self.actions(actions);

		//if showing a list...
		self.showList = ko.observable(false);
		self.loadingList = ko.observable(false);
		self.list = ko.observableArray(null);
		self.listNoLink = ko.observable(false);
		self.listItemClick = function (listItem) {
		}


		self.modalOptions = {
			hasCancelAction: self.hasCancelAction,
			hide: function () {
				self.doCancelAction();
			},
			size: size,
			destroy: function () {
				//remove this Message
				ko.cleanNode(document.getElementById("message-modal"));
				$("#message-modal").hide();
			}

		}


	}


	window.ContentManager.ViewModels.Navigation = {
		currentLanguageCode: () => {
			return window.currentAgilityLanguageCode;
		},
		currentWebsite: () => websiteName,
		messages: () => {
			return {
				show: (message: string, title: string, type: string, buttons: any[]) => {

					const m = new Message(message, title, type, buttons);
					ko.applyBindings(m, document.getElementById("message-modal"));
					$("#message-modal").show();
				}
			}
		}
	}
}