
interface Props {
	managerUrl: string
	websiteName: string
	url: string
	args: any

}

export const authenticatedCall = async ({ managerUrl, websiteName, url, args }: Props): Promise<any> => {

	const fullUrl = `${managerUrl}${url}`

	let init: RequestInit = {
		method: "POST",
		credentials: "include", // include, *same-origin, omit
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
		body: JSON.stringify({
			websiteName: websiteName,
			...args

		}),
	}

	const response = await fetch(fullUrl, init)

	if (!response.ok) {
		// *** NOT ok ***
		throw new Error(`Error in API call to ${url}. Request returned a ${response.status} response  for ${fullUrl}.`)
	}

	// *** OK ***
	const retObj = await response.json()

	if (!retObj) return null

	if (retObj.IsError === true) {
		throw new Error(retObj.ErrorMessage || `An unexpected error occurred. in API call to ${url}`)
	} else {
		if (retObj.ResponseData !== undefined) {
			return retObj.ResponseData
		}

		return retObj
	}

}