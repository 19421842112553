import queryString from 'query-string';

import { addMessageListener } from "./messages/window-listener";
import plugin from 'tailwindcss';
import { sendMessage } from './messages/send-message';
import { renderField } from './render-field';
import { setupShowMessage } from './compat/show-message';
import { setupDataAccess } from './compat/data-access';
import { setupDevMode } from './compat/dev-mode';
import { loadCustomKnockoutBindings } from './compat/custom-ko-bindings';


//@ts-ignore
window.ContentManager = {
	Developer: {
		DevMode: () => false
	},
	Global: {

		AlertType: {
			Info: "info",
			Warning: "warning",
			Error: "danger"
		},
		MessageType: {
			Info: "info",
			Warning: "warning",
			Error: "danger",
			Question: "question",
			None: "none"
		},
		MessageSize: {
			Small: 'small',
			Medium: 'medium',
			Large: 'large'
		},
		CustomInputFormFields: []
	}, ViewModels: {}
};

//@ts-ignore
const ContentManager = window.ContentManager;

$(document).ready(function () {

	const query = queryString.parse(location.search);
	const websiteName = query.website as string;
	const fieldType = query.fieldType as string;
	const fieldName = query.fieldName as string;
	const managerUrl = query.managerUrl as string;
	const fieldscript = `${query.script}`;


	console.log("Initializing Custom Field:", fieldName);

	if (!websiteName || !fieldscript) {
		console.error("websiteName or script not defined");
		return

	}

	//setup compatibility layers...
	loadCustomKnockoutBindings()
	setupShowMessage({ websiteName, managerUrl })
	setupDataAccess({ managerUrl })
	setupDevMode({ managerUrl, websiteName })


	//load the script
	$.getScript(fieldscript, function () {


		//once we've loaded the script, we can send the init message
		sendMessage({
			eventName: "init",
			websiteName,
			fieldName,
			fieldType,

		});
	}).fail(function (exception) {
		console.warn("Error loading script", exception);
	})



	addMessageListener((event) => {

		//when we get the init event...
		if (event.data.eventName === "init") {

			//set some context stuff (locale!)
			const contentItem = event.data.contentItem;
			const locale = contentItem.LanguageCode;
			//@ts-ignore
			window.currentAgilityLanguageCode = locale

			renderField({
				websiteName,
				fieldName,
				fieldType,
				managerUrl,
				readonly: event.data.readonly,
				contentItem,
				field: event.data.field,
				attemptNum: 1

			})


		} else if (event.data.eventName === "value-change") {
			const value = event.data.value;
			const fieldName = event.data.fieldName;

			//update the value in the content item
			//@ts-ignore
			const pluginOptions = window.pluginOptions

			if (pluginOptions) {
				pluginOptions.contentItem.Values[fieldName](value)
			}
		}
	});
})