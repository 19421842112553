//@ts-nocheck
import { getInlineTemplateURL } from "../data-access/getInlineTemplateURL";

interface Props {
	managerUrl: string
}


export const setupDataAccess = ({ managerUrl }: Props) => {

	window.ContentManager.DataAccess = {
		PostCORS: function (url, data, callback, errorCallback, alwaysCallback) {

			if (url.indexOf("/") == 0) url = managerUrl + url;

			return $.ajax(url, {
				type: "POST",
				data: data,
				dataType: "json",
				headers: _headers,
				xhrFields: {
					withCredentials: true
				},
				crossDomain: true
			})
				.success(function (result) {

					if (result.IsError) {

						if (result.StackTrace) {
							if (console && console.warn) {
								console.warn(result.ErrorMessage, result.StackTrace);
							}
						}

						if (errorCallback != undefined && $.isFunction(errorCallback)) {
							errorCallback(result.ErrorMessage, "management");
						} else {

							alert("An error occurred.  Please refresh your browser and try again");

						}

					} else {
						//redirect to the return url
						if (callback != undefined && $.isFunction(callback)) {
							if (result.ResponseData === undefined)
								callback(result);
							else
								callback(result.ResponseData);
						}

					}
				})
				.fail(function (err, status, errorMessage) {

					if (status == "abort") {
						//do nothing...
						return;
					}

					if (errorCallback != undefined && $.isFunction(errorCallback)) {
						errorCallback(errorMessage, status);
					} else {
						alert("An error occurred.  Please refresh your browser and try again.");
					}
				})
				.always(function () {
					if (alwaysCallback != undefined && $.isFunction(alwaysCallback)) {
						alwaysCallback();
					}
				});
		},
		GetInlineTemplateUrl: function (referenceNames, callback, errorCallback, always) {

			var websiteName = ContentManager.ViewModels.Navigation.currentWebsite();


			getInlineTemplateURL({ managerUrl, websiteName, referenceName: referenceNames[0] })
				.then((templateUrl) => {
					if (callback) callback(templateUrl)
				}).catch((error) => {
					console.warn("Error getting inline template url:", error)
					if (errorCallback) errorCallback(error)
				}).finally(() => {
					if (always) always()
				});
		}
	}

	window.ContentManager.SettingsDataAccess = {
		SelectAllDigitalChannels: function (websiteName, callback, error, always) {

			var url = "/json/Settings/SelectAllDigitalChannels";
			var data = {
				websiteName: websiteName
			};

			return D.PostCORS(url, data, callback, error, always);
		}
	}

	const D = window.ContentManager.DataAccess;
	const _headers = { 'X-Requested-With': 'XMLHttpRequest' }

	window.ContentManager.ContentDataAccess = {
		GetContentItem: function (websiteName, contentViewID, itemContainerID, languageCode, contentItemID, callback, error, always) {
			var url = "/json/Content/GetContentItem";
			var data = {
				websiteName: websiteName,
				contentViewID: contentViewID,
				itemContainerID: itemContainerID,
				languageCode: languageCode,
				contentItemID: contentItemID
			};

			return D.PostCORS(url, data, callback, error, always);
		},
		GetContentViewByReferenceName: function (websiteName, referenceName, callback, errorCallback, alwaysCallback) {
			var url = "/json/Content/GetContentViewByReferenceName";
			return D.PostCORS(url, {
				websiteName: websiteName,
				referenceName: referenceName
			},
				callback,
				errorCallback,
				alwaysCallback);
		},
		GetContentList: function (data, callback, errorCallback, alwaysCallback) {
			var url = "/json/Content/List";
			return D.PostCORS(url, data,
				callback,
				errorCallback,
				alwaysCallback
			);
		}
	}

	window.Agility = {};
	window.Agility.CMS = {};
	window.Agility.CMS.API = {

		GetContentItems: function (contentReferenceName, options, callback, errorCallback) {

			var DefaultValues = {
				languageCode: ContentManager.ViewModels.Navigation.currentLanguageCode(),
				filter: "",
				tagContainerIDs: [],
				sort: "",
				columns: [],
				sortedItemContainerIDs: []
			};

			//merge the options
			var args = $.extend({}, DefaultValues, options);
			args.contentReferenceName = contentReferenceName;

			var sort = args.sort;
			var sortDirection = "";
			if (sort.indexOf(" ") != -1) {
				sortDirection = sort.substr(0, sort.indexOf(" "));
				sort = sort.substr(sort.indexOf(" ") + 1);
			}

			var gridPostData = {
				websiteName: ContentManager.ViewModels.Navigation.currentWebsite(),
				languageCode: args.languageCode,
				contentViewID: -1,
				contentReferenceName: args.contentReferenceName,
				columnStr: args.columns.join(","),
				isRecyclingBin: false,
				filter: args.filter,
				pageSize: 500,
				recordOffset: 0,

				sortField: sort, // contentView.DefaultSortColumn,
				sortDirection: sortDirection, // contentView.DefaultSortDirection,
				sortIDs: null

			};


			ContentManager.ContentDataAccess.GetContentList(gridPostData,
				function (result) {
					callback(result.Items);
				},
				errorCallback);

		}
	};
}