//@ts-nocheck

import { getInlineScriptURL } from "../data-access/getInlineScriptURL";

interface Props {
	managerUrl: string
	websiteName: string
}

export const setupDevMode = ({ managerUrl, websiteName }: Props) => {

	window.ContentManager.Developer = {
		DevMode: ko.observable(false),
		InlineCode: {
			LoadJS: function (referenceName, callback) {

				getInlineScriptURL({ managerUrl, websiteName, referenceName }).then((url) => {
					console.log("Script URL", url)
					$.getScript(url).done(function () {
						if ($.isFunction(callback)) {
							callback()
						}
					});

				})
			}
		}
	}

	console.log("Setting up dev mode", { managerUrl, websiteName }, ContentManager.Developer)

}
